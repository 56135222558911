@import 'styles/colors.scss';

.FormTitle {
  color: $wse-title;
  font-family: sans-serif;
  font-size: 14px;
  margin: auto 10px;
  font-weight: 400;
  letter-spacing: 0.18px;
  line-height: 14px;
  //text-transform: uppercase;

  &__disabled {
    color: $wse-grey;
  }
}
