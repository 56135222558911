@import "src/styles/colors.scss";

.Gen {
    width: 100%;
    height: 100%;
    // min-height: calc(100vh - var(--header-height));
    // overflow-x: hidden;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.Gen__section-wrapper {
    width: 100%;
    height: 100%;
    justify-content: center;
    @media (min-width: 1000px) {
        display: flex;
    }
}

.Gen__beta {
    margin: 10px 10px 0px;
    padding: 10px;
    max-width: 1000px;
    border-radius: 4px;
    text-align: center;
    background-color: $wse-gen-background;
    // border: 1px solid $wse-gen-marker;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    @media (min-width: 1000px) {
        margin: 10px auto 0px;
    }
}

.Gen__section {
    width: 100%;
    height: 800px;
    max-width: 700px;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1000px) {
        height: 100%;
        max-height: unset;
        margin: unset;
    }
}

.Gen__gen-template {
    margin: auto;
}

.Gen--compact {
    & .Gen__section,
    & .Gen__module,
    & .Namespace__wrapper {
        height: unset;
    }
    & .Namespace__wrapper {
        position: unset;
        min-height: 150px;
    }
    & .FileInput__wrapper {
        position: unset;
        min-height: 300px;
    }
}

.Gen__module {
    margin: 10px;
    padding: 10px;
    height: 100%;
    background-color: $wse-gen-background;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    color: $wse-orange;

    &__header {
        display: flex;

        .Title {
            flex-grow: 1;
        }
    }

    &--project,
    &--options {
        height: auto;
    }
}

.Gen__remove-all {
    display: flex;
    margin: auto;
    align-items: center;

    border-radius: 3px;
    padding: 2px 6px;
    cursor: pointer;

    color: rgb(163, 163, 163);

    &:hover {
        background-color: rgba(121, 121, 121, 0.151);
    }
}

.Gen__module--build {
    height: auto;
    display: flex;
    justify-content: center;
}

.Gen__modal {
    background-color: $wse-gen-background;
}

.Gen__err-log {
    > .Gen__err-title {
        display: flex;
        height: 23px;
        margin-bottom: 14px;

        .Title {
            flex-grow: 1;
            margin: 0;
        }

        > .Gen__err-select-all {
            padding: 0 2px;
            display: flex;

            align-items: center;
            height: 100%;
            border-radius: 3px;
            cursor: pointer;
            color: rgb(194, 194, 194);

            &:hover {
                background-color: rgba(121, 121, 121, 0.151);
            }

            > img {
                margin-left: 3px;
                height: 100%;
            }
        }
    }

    > .Gen__err {
        margin: 3px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
        color: rgb(163, 163, 163);

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
        }

        &:last-of-type {
            border: none;
        }
    }
}
