@import "src/styles/colors.scss";

.Help {
  height: 100%;
}

.Help__DesktopMenu {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: row-reverse;
  background-color: #f5f7f9;
}

.Help__Grid {
  width: 100%;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;
  background-color: white;
  min-height: 100%;

  @media screen and (max-width: 799px) {
    grid-template-columns: 1fr;
  }
}

.Help__Left {
}

.Help__Right {
  width: 100%;
  position: relative;
  display: grid;
}

.Help__Document {
  // margin-top: 50px;
  padding: 50px;
  max-width: 800px;
  width: 100%;
  display: block;

  @media screen and (max-width: 1399px) {
    padding: 20px;
  }
}

.Help--hideTOC .stackedit__left {
  display: none;
}

.Help__Document {
  width: 100%;
  overflow: hidden;

  margin-bottom: 300px;
  // position: relative;
}


.DocumentTOC {

  width: fit-content;
  
  display: flex;
  flex-direction: column;
  padding: 20px;
  
  @media screen and (min-width: 1400px) {
    max-width: calc(100vw - 1115px);
    position: fixed;
    top: 70px;
    left: 1100px;
    padding: 0px;


  }

  .DocumentTOC__Section,
  .DocumentTOC__Section:hover {
    text-decoration: none;
    color: hsl(206, 23%, 12%);
  }

  h1.DocumentTOC__Section {
    font-weight: normal;
    margin-top: 1.8em;
    color: hsl(206, 23%, 12%);
    font-size: 20px;
    font-weight: bold;
  }

  .DocumentTOC__Section {
    // width: fit-content;
    margin: 3px;

    > * {
      // width: fit-content;
      margin: 0;
      padding: 4px;
      font-weight: normal;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    > h2 {
      color: hsl(206, 23%, 30%);
      font-size: 17px;
    }

    > h3 {
      color: hsl(206, 23%, 40%);
      padding-left: 20px;
      font-size: 15px;
    }
  }
}
