.HelpTOC {
    
}


.HelpTOC__HeaderFiller {
    height: 100px;
}

.HelpTOC__Header {
    height: 100px;
    width: 299px;

    background-color: #f5f7f9;
    border: 1px solid #e0e0e0;
    border-width: 0 0 1px 0;

    padding: 0 20px;
    display: flex;
    align-items: center;

    .HelpTOC__icon {
        height: 60px;
        padding: 5px;
        border-radius: 50%;
        &:hover {
            background-color: rgba(0,0,0,0.05);
        }
    }

    .HelpTOC__title {
        margin-left: 20px;
        font-size: 20px;
        font-family: Quicksand;
        font-weight: bold;
        color: rgb(82, 82, 82);
    }
    
}

.HelpTOC__Header--isDesktop {
    position: fixed;
    top: 0;
    left: 0;   
}

.HelpTOC__Content {
    padding: 30px 0 200px 20px;
    height: calc(100vh - 100px);
    overflow-y: auto;
}

.HelpTOC__Folder {
    margin-top: 20px;

    .HelpTOC__FolderTitle {
        color: #c57017;
        font-size: 12px;
        font-family: Content-font, Roboto, sans-serif;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        padding: 8px 24px 8px 16px;
    }
}

.HelpTOC__FileLink, .HelpTOC__FileLink:hover {
    text-decoration: none;
}

.HelpTOC__File {
    padding: 8px 24px 8px 16px;
    color: #717171;
    font-size: 14px;
    word-break: break-word;
    font-family: Content-font, Roboto, sans-serif;
    font-weight: 500;
    line-height: 1.5;

    // border-radius: 15px 0 0 15px;
    margin-bottom: 2px;

    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.082);
        text-decoration: none;
    }
}

.HelpTOC__File--active {
    background-color: white;
    border-color: #e0e0e0;
    
    
    // color: black;
    &:hover {
        background-color: white;
    }
}