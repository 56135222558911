@import "src/styles/colors.scss";

.PhoneBar {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;

    background-color: transparent;
    // background-color: $wse-topbar-background;
    height: var(--header-height);
    width: 100%;

    padding: 20px;

    transition: all 0.4s ease;

    &--scrollTrigger {
        transform: translateY(-100%);
    }
}

// .PhoneBar:not(.PhoneBar--scrollTop) {
//     background-color: transparent;

//     .PhoneBar__burger {
//         background-color: $wse-topbar-background;
//     }
// }

.PhoneBar__burger {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    // background-color: #2e2e2e;
    background-color: $wse-topbar-background;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    border: none;
    outline: none;

    > div {
        width: 18px;
        height: 3px;
        background-color: white;
        border-radius: 3px;
        margin-bottom: 3px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.PhoneBar__ClusterLeft {
}

.PhoneBar__Overlay {
    z-index: 6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;

    background-color: black;
    opacity: 50%;

    transition: opacity 0.2s ease;

    &:not(.PhoneBar__Overlay--isOpen) {
        opacity: 0;
        pointer-events: none;
    }
}

.PhoneBar__Expanded {
    z-index: 7;
    position: fixed;
    top: 0;
    left: 0;

    background-color: $wse-topbar-background;
    // height: calc(var(--header-height) * 3);
    width: 100%;

    padding: 20px;

    transform: translateY(-100%);
    transition: transform 0.2s ease;

    &--isOpen {
        transform: translateY(0%);
    }

    .PhoneBar__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.PhoneBar__logo {
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.PhoneBar__divider {
    // width: 100%;
    margin: 10px 0;
    height: 2px;
    background-color: #303030;
    border-radius: 1px;
}

.PhoneBar__left .TopBarButton:not(.TopBarButton--active) > div:hover {
    background: none;
}

.PhoneBar__links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.PhoneBar__right {
    display: flex;
    align-items: center;
}

.PhoneBar__generate > div {
    margin: auto 5px;
    padding: 6px 20px;
    border-radius: 32px;
    cursor: pointer;

    
}
.PhoneBar__generate, .PhoneBar__generate:hover {
    color: white;
    font-family: Quicksand;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.22px;
    line-height: 17px;
}

