.Modal.bp3-dialog {
    border-radius: 0;
    padding: 30px;
    position: relative;
}

.Modal__close-button {
    position: absolute;
    top: 0;
    right: 0;
}

.Modal.bp3-drawer {
    padding: 10px;
}