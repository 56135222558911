@import "src/styles/colors.scss";

.FormCheckbox.MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}
.FormCheckbox .MuiButtonBase-root:hover {
  background-color: unset;
} 

.FormCheckbox .MuiFormControlLabel-label {
  font-size: 14px;
  margin: 0 10px;
}
.FormCheckbox .MuiCheckBox-colorSecondary.MuiIconButton-colorSecondary {
  color: #ddd;
}

.FormCheckbox .MuiButtonBase-root.Mui-checked {
  color: $wse-gen-marker;
}

.FormCheckbox .MuiButtonBase-root {
  color: #ddd;
  padding: 0;
}

.FormCheckbox .MuiSvgIcon-root {
  font-size: 30px;
}

.Inputs--invalid .FormCheckbox .MuiCheckbox-root {
  color: $wse-light-red;
}
