@import "styles/colors.scss";

.WseButton__link,
.WseButton__link:hover {
    text-decoration: none;
}

.WseButton__layout {
    cursor: pointer;

    $border: 3px;
    $radius: 20px;

    position: relative;
    outline: none;

    border-radius: $radius;
    background-color: inherit;
    // z-index: 1;

    .WseButton__border {
        border-radius: $radius;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        // background-color: red;
        background: linear-gradient(45deg, #eb941c -4.59%, #d82800 107.65%);

        transition: all 0.1s ease;
        // z-index: 0;
    }

    .WseButton {
        position: relative;
        height: 40px;
        border: solid $border transparent;
        border-radius: $radius;
        padding: 5px 20px;
        color: white;

        display: flex;
        flex-direction: row;
        align-items: center;

        background-clip: padding-box;
        background-color: inherit;
        // z-index: 1;
    }
}

@media screen and (min-width: 800px) {
    .WseButton__layout--rotate:hover .WseButton__border {
        background: linear-gradient(-45deg, #eb941c -4.59%, #d82800 107.65%);

        transform: scale(1.1, 0.9) rotate(7deg);
    }
}
