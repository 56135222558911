

.mermaid > svg {
    height: auto;

    g rect.note {
        
    }

    
    .actor {
        rx: 0;
        ry: 0;
        stroke-width: 0;
    }

    .activation0 {
        // fill: rgb(155, 155, 155) !important;
        // stroke: rgb(134, 95, 22) !important;
        // stroke-width: 2;
    }

}