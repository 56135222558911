.TopBarButton > div {
  margin: auto 5px;
  padding: 10px 23px;
  border-radius: 32px;
  cursor: pointer;
  
}



.TopBarButton, .TopBarButton:hover {
  color: white;
  font-family: Quicksand;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.22px;
  line-height: 17px;
  font-weight: 500;
}

.TopBarButton--active > div {
  background: #2E2E2E;
}

.TopBarButton > div:hover {
  background: #2E2E2E;
}

