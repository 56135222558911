@import "src/styles/colors.scss";

.WsdlTemplateModal {
    background-color: $wse-gen-background;

    .WsdlTemplateModal__target {
        text-align: end;
    }

    .WsdlTemplateModal__download {
        margin: 10px auto 0;
    }
}
