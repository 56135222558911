@import 'styles/colors.scss';

.Select__select {
  height: 48px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid $wse-input-border;
  background-color: $wse-input-background;
  font-size: 14px;
  line-height: 25px;
  padding: 6px 16px;
  text-indent: 10px;
  
  &.Select__value-default {
    color: $wse-input-color;
  }

  > option {
    color: $wse-input-color;
  }
}

.Inputs--invalid select {
  outline: 2px auto $wse-light-red;
  outline-offset: -2px;
  box-shadow: 0 0 4px 0 $wse-light-red;
}
