@import "src/styles/colors.scss";

.FileInput.Inputs {
    margin: 0px;
}

.Gen__module--input {
    display: flex;
    flex-direction: column;
}

.FileInput {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.1s linear 0.2s;
    border: 2px solid transparent;
    border-radius: 10px;
}
.Gen--drag .FileInput {
    border: 2px solid rgba(255, 165, 0, 1);
    box-shadow: inset 0px 0px 5px 0px rgba(255, 165, 0, 0.5),
        0px 0px 5px 0px rgba(255, 165, 0, 0.5);
    transition: all 0.1s linear;
}

.FileInput__drop-info {
    position: absolute;
    text-align: center;
    top: calc(50% - 50px);
    left: calc(50% - 125px);
    width: 250px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s linear 0.2s;
    img {
        height: 45px;
    }
}

.Gen--drag .FileInput__drop-info {
    opacity: 0.3;
    transition: opacity 0.1s linear;
}

.FileInput__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: opacity 0.1s linear 0.1s;
}

.Gen--drag .FileInput__wrapper {
    opacity: 0.1;
    transition: opacity 0.1s linear;
}

.FileInput__form {
    width: 1px;
    height: 50px;
    opacity: 0;
    pointer-events: none;
}

.File {
    height: 30px;
    margin: 5px;
    display: flex;
    border-radius: 10px;

    transition: background-color 0.1s ease-in-out;
    color: $wse-input-text-color;

    .text-input {
        width: 100%;
    }
    img {
        margin: 2.5px;
        width: 25px;
        height: 25px;
    }

    &:hover {
        transition: background-color 0s linear;
        background-color: rgba(0, 0, 0, 0.03);
    }
}

.File__name {
    width: 100%;
    margin: auto 20px;
}

.File__icon:hover .File__err {
    visibility: visible;
    height: unset;
    width: unset;
}

.File__err {
    z-index: 9;
    visibility: hidden;
    position: absolute;
    pointer-events: none;

    overflow: hidden;
    padding: 2px 4px;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    height: 0;
    width: 0;
}
