@import "styles/colors.scss";

.Runtimes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 20px 0;

    > .Text {
        max-width: 460px;
        margin-bottom: 60px;
        a {
            color: white;
        }
    }
}

.Runtimes .WseButton {
    background-color: $wse-bg;
}

.Runtimes__section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Runtimes__section--platforms {
    margin-bottom: 60px;
}

.Runtimes__Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin: 5px;

    height: 140px;
    width: 100px;

    background: #2b2b2b;
    border-radius: 17px;

    cursor: pointer;
    transition: all 0.1s linear;

    @media (min-width: 1000px) {
        &:hover {
            background: #1E1E1E;
        }
    }

    

    > img {
        width: 64px;
        height: 70px;
        object-fit: contain;
    }

    > div {
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        text-align: center;

        color: #999999;

        white-space: nowrap;
    }

    &--smallText > div {
        font-size: 14px;
        line-height: 17px;
    }
}
