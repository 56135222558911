.Workflow {
    width: 100%;

    padding: 100px 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .Workflow__step-wrapper {
        width: 33.33%;
        min-width: 200px;
    }

    .Workflow__step {
        max-width: 200px;
        margin: 20px;
        text-align: center;

        img {
            height: 60px;
        }
    }

    .Workflow__step-index {
        border: 1px solid gray;
        border-radius: 100%;
        padding: 2px 7px;
        margin-right: 5px;
        font-weight: bold;
    }
}
