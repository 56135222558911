@import "src/styles/colors.scss";

.TopBar__filler {
    height: var(--header-height);
}

// Desktop Bar

.DesktopBar {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;

    background-color: $wse-topbar-background;
    height: var(--header-height);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.DesktopBar__center {
    position: relative;
    height: 100%;
    width: 1200px;
    max-width: 100%;
    margin: auto;
    padding: 0 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DesktopBar__ClusterLeft {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
}

.DesktopBar__ClusterLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.DesktopBar__generate {
    margin: auto 5px;
    padding: 6px 20px;
    border-radius: 32px;
    cursor: pointer;

    background: linear-gradient(71.33deg, #56CCFF -4.59%, #7741D0 107.65%);
}
.DesktopBar__generate,
.DesktopBar__generate:hover {
    color: white;
    font-family: Quicksand;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.22px;
    line-height: 17px;
}

.DesktopBar__logo {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    margin-right: 20px;
    padding: 5px;

    > img {
        height: 100%;
    }

    &:hover {
        background: #2e2e2e;
    }
}

// Phone Bar

