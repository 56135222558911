@import "src/styles/colors.scss";

.Inputs {
  margin: 10px 0;

  .input-required {
    font-size: 14px;
    margin-left: 5px;
    color: $wse-bright-red;
  }

  :focus {
    outline: none;
    border-color: $wse-gen-marker;
  }
}
