@import "src/styles/colors.scss";

.HelpMenuBar__TopBar {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;

    background-color: transparent;
    // background-color: $wse-topbar-background;
    height: var(--header-height);
    width: 100%;

    padding: 20px;

    transition: all 0.4s ease;

    &--scrollTrigger {
        transform: translateY(-100%);
    }
}

.HelpMenuBar__burger {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    // background-color: #2e2e2e;
    background-color: rgba(233, 233, 233, 0.438);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    border: none;
    outline: none;

    > div {
        width: 18px;
        height: 3px;
        background-color: rgb(80, 80, 80);
        border-radius: 3px;
        margin-bottom: 3px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.HelpMenuBar__Overlay {
    z-index: 6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;

    background-color: black;
    opacity: 20%;

    transition: opacity 0.2s ease;

    &:not(.HelpMenuBar__Overlay--isOpen) {
        opacity: 0;
        pointer-events: none;
    }
}

.HelpMenuBar__SideMenu {
    z-index: 7;
    background-color: #f5f7f9;
    position: fixed;
    top: 0;
    bottom: -100px;
    left: 0;

    width: 300px;

    transition: transform 0.2s ease;

    &:not(.HelpMenuBar__SideMenu--isOpen) {
        transform: translateX(-100%);
    }
}

.HelpMenuBar__Menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #f5f7f9;
    width: 300px;
    height: 100%;
    border: 1px solid #e0e0e0;
    border-width: 0 1px 0 0;
}