.Footer {

    margin-top: 150px;

    .Footer__layout {
        padding: 30px 20px 50px;
        background-color: #222;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

    }

    .Footer__row {
        padding: 10px;
        background-color: #111;
        color: rgb(153, 153, 153);
    }

    .Footer__card {
        padding: 20px 60px;
        display: flex;
        flex-direction: column;
        
        .Title {
            color: rgb(172, 172, 172);
            font-weight: 600;
        }

        a {
            font-weight: 600;
            color: rgb(134, 134, 134);
            padding: 5px 0px;
        }

    }
}