@import "src/styles/colors.scss";

.SuccessModal {
    background-color: $wse-gen-background;

    .SuccessModal__result-url {
        cursor: pointer;
        text-align: center;
        white-space: pre-wrap;
    }
}