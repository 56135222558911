@import "styles/colors.scss";

:root {
  --header-height: 80px;
  --header-expanded-height: 140px;
  background-color: $wse-bg;
}

html {
  scroll-behavior: smooth;
  scroll-margin-inline: nearest;
  scroll-snap-align: end;
  
}

.WseBody {
  width: 100%;
}

.w{
	width: 100%;
}

.App-link {
  color: #61dafb;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
