.language-xml:not(.nohighlight) {
    .hljs-tag {
        color: black;
    }

    .hljs-name {
        color: #A52A2A;
    }

    .hljs-attr {
        color: blue;
    }
}