@import "src/styles/colors.scss";

.TextInput {
  text-align: left;
  display: flex;

   .text-input {
    border: 0 solid $wse-input-border;
    border-width: 0 0 2px 0;
    color: $wse-input-text-color;
    font-size: 14px;
    padding: 2px 2px;
    
    background: none;
  }
}

.TextInput__title {
  color: $wse-black;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.TextInput.TextInput--disabled .text-input {
  color: $wse-grey
}

.TextInput--hasIcon {
  position: relative;

  .text-input {
    padding-left: 35px;
  }

  svg {
    position: absolute;
    left: 12px;
    bottom: 16px;
  }
}