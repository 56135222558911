.DonateContent {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .Title {
        margin-bottom: 20px;
    }

    > .Text {
        margin-bottom: 50px;
    }
}
