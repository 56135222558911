@import "styles/colors.scss";

.Title {
  font-family: Quicksand;
  font-weight: 500;
  text-align: left;
  color: $wse-title;
  margin-bottom: 14px;
}

.Title--xs {
  font-size: 15px;
}
.Title--sm {
  font-size: 21px;
}
.Title--md {
  font-size: 28px;
}
.Title--lg {
  font-size: 35px;
}

@media (max-width: 1024px) {
  .Title--auto.Title--sm {
    font-size: 17px;
  }
  .Title--auto.Title--md {
    font-size: 25px;
  }
  .Title--auto.Title--lg {
    font-size: 34px;
  }
}
@media (max-width: 574px) {
  .Title--auto.Title--sm {
    font-size: 16px;
  }
  .Title--auto.Title--md {
    font-size: 23px;
  }
  .Title--auto.Title--lg {
    font-size: 30px;
  }
}

.Title--white {
  color: $wse-white;
}

.Title--green {
  color: $wse-green;
}

.Title--red {
  // Not a variable because it is same as comparison red,  not ordinary red
  color: #d0021b;
}

.Title--bright-red {
  color: $wse-bright-red;
}

.Title--bold {
  font-weight: bold;
}

.Title--center {
  text-align: center;
}
