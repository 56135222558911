.GenButton > div {
  margin: auto 5px;
  padding: 10px 23px;
  border-radius: 32px;
  cursor: pointer;
  background: linear-gradient(45deg, #eb941c -4.59%, #d82800 107.65%);
}

.GenButton,
.GenButton:hover {
  color: white;
  font-family: Quicksand;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.22px;
  line-height: 17px;
  font-weight: 500;
}
