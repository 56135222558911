@import "src/styles/colors.scss";

.About {
    padding: 100px 20px 0;

    @media (max-width: 999px) {
        padding: 20px 20px 50px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    > .WseLogo {
        margin-bottom: 30px;
    }

    > .Text {
        max-width: 488px;
        margin-bottom: 30px;
    }

    .About__links {
        width: 100%;
        max-width: 340px;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        div,
        a {
            font-family: Quicksand;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 25px;
            /* identical to box height */

            text-align: center;

            color: #ffffff;
            // text-decoration: none;
        }

        div {
            padding: 10px;
        }
    }
}

.About__card {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 150px 20px 0;
    

    > .Text {
        max-width: 488px;
        margin-bottom: 30px;

        a {
            color: white;
        }
    }

    .WseButton {
        background-color: $wse-bg;
    }
}
