.Resources {
    padding: 20px;
}

.Resources--Desktop {
    width: 100%;
    display: flex;
    justify-content: center;

    // .Resources__Grid {
    //     width: 100%;
    //     max-width: 1280px;
    //     height: 200px;

    //     display: grid;
    //     grid-template-columns: 1fr 350px;
    //     grid-template-rows: 1fr;
    //     grid-column-gap: 20px;
    //     grid-row-gap: 0px;
    // }
}
