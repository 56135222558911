@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import './colors.scss';
// @import './docstyles.scss';
@import './hljs.scss';
@import './mermaid.scss';
@import './showdown.scss';

.bp3-overlay-backdrop {
    background-color: rgba(16, 22, 26, 0.2);
}