@import 'styles/colors.scss';

.Text {
  .checkbox-container {
    display: inline-block;
    padding-top: 8px;
    padding-right: 10px;
    cursor: pointer;
  }
}

.Inputs--invalid .text-input {
  box-shadow: 0 0 4px 0 $wse-light-red;
}

.Text .FormError {
  margin-top: 10px;
}