@import './hljs-xml.scss';

pre code {
  border-left: 3px solid rgb(230, 157, 108);
  display: block;
  padding: 0.5em;
  -webkit-text-size-adjust: none;
  overflow-x: auto;
  white-space: pre;
  overflow-wrap: break-word;
  width: 100%;
  tab-size: 3;
  border-radius: 0px;
}

code {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 2px 4px;
}

code:not(.nohighlight) {
  code.hljs {
    display: block;
    overflow-x: auto;
    padding: 1em;
  }
  code.hljs {
    padding: 3px 5px;
  } /*!
  Theme: a11y-light
  Author: @ericwbailey
  Maintainer: @ericwbailey

  Based on the Tomorrow Night Eighties theme: https://github.com/isagalaev/highlight.js/blob/master/src/styles/tomorrow-night-eighties.css
*/
  .hljs {
    background: #fefefe;
    color: #545454;
  }
  .hljs-comment,
  .hljs-quote {
    color: #308628;
  }
  .hljs-deletion,
  .hljs-name,
  .hljs-regexp,
  .hljs-selector-class,
  .hljs-selector-id,
  .hljs-tag,
  .hljs-template-variable,
  .hljs-variable {
    color: #d91e18;
  }

  .hljs-attribute,
  .hljs-built_in,
  .hljs-link,
  .hljs-literal,
  .hljs-meta,
  .hljs-number,
  .hljs-params,
  .hljs-type {
    color: #aa5d00;
  }
  .hljs-addition,
  .hljs-bullet,
  .hljs-string,
  .hljs-symbol {
    color: green;
  }
  .hljs-section,
  .hljs-title {
    color: #007faa;
  }
  .hljs-keyword,
  .hljs-selector-tag {
    color: #7928a1;
  }
  .hljs-emphasis {
    font-style: italic;
  }
  .hljs-strong {
    font-weight: 700;
  }
  @media screen and (-ms-high-contrast: active) {
    .hljs-addition,
    .hljs-attribute,
    .hljs-built_in,
    .hljs-bullet,
    .hljs-comment,
    .hljs-link,
    .hljs-literal,
    .hljs-meta,
    .hljs-number,
    .hljs-params,
    .hljs-quote,
    .hljs-string,
    .hljs-symbol,
    .hljs-type {
      color: highlight;
    }
    .hljs-keyword,
    .hljs-selector-tag {
      font-weight: 700;
    }
  }
}

.Help__Document {
  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  figcaption,
  figure {
    display: block;
  }
  figure {
    margin: 1em 40px;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  main {
    display: block;
  }
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  dfn {
    font-style: italic;
  }
  mark {
    background-color: #ff0;
    color: #000;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  audio,
  video {
    display: inline-block;
  }
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  img {
    max-width: 100%;
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [type="reset"],
  [type="submit"],
  button,
  html [type="button"] {
    -webkit-appearance: button;
  }
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  input {
    overflow: visible;
  }
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box;
    display: table;
    max-width: 100%;
    padding: 0;
    color: inherit;
    white-space: normal;
  }
  progress {
    display: inline-block;
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }
  menu {
    display: block;
  }
  canvas {
    display: inline-block;
  }
  [hidden],
  template {
    display: none;
  }

  blockquote,
  dl,
  ol,
  p,
  pre,
  ul {
    margin: 1.2em 0;
    line-height: 1.5;
  }

  // Keep pre and filename the same, filename * -1
  pre {
    margin-bottom: 1.2em;
  }
  .filename {
    margin-bottom: -1.2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.8em 0 1.8em;
    line-height: 1.33;
  }
  h1:after,
  h2:after {
    content: "";
    display: block;
    position: relative;
    top: 0.33em;
    border-bottom: 1px solid hsla(0, 0%, 50%, 0.33);
  }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin: 0;
  }
  dt {
    font-weight: 700;
  }
  a {
    color: #0c93e4;
    text-decoration: underline;
    text-decoration-skip: auto;
  }
  img {
    max-width: 100%;
    border-style: none;
  }
}
