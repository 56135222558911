@import "src/styles/colors.scss";

.IconButton {
    margin: 2.5px;
    height: 35px;
    width: 35px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &--sm {
        height: 25px;
        width: 25px;
    }

    &--force {
        min-height: 35px;
        min-width: 35px;
    }

    .MuiSvgIcon-root {
        height: 100%;
        width: 100%;
    }
    &:hover {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    }
}

.IconButton--borderless {
    box-shadow: none;
    color: gray;
    &:hover {
        box-shadow: none;
        color: $wse-orange;
    } 
}

.IconButton--force.IconButton--sm {
    min-height: 25px;
    min-width: 25px;
}