.Help__Document {

    .filename {
        
        border: 3px solid #e69d6c;
        border-width: 0 0 0px 3px;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.1);
        font-weight: 500;
    }
}