.DonateButton {
    margin-top: 20px;
    background-color: #5AA74E;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    padding: 20px 50px;

    white-space: nowrap;

    &:hover {
        background-color:  #67b35b;
        text-decoration: none;
    }
}