@import 'styles/colors.scss';

.TabSelect {
    display: flex;
}

.TabSelect .TabSelect__option {

    margin: 5px;
    padding: 5px 10px;
    // box-shadow: 0px 0px 5px 0px rgba(236, 140, 50, 0.466);
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    color: white;

    &:hover {
        // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 5px 0px rgba(236, 140, 50, 0.466);
    }

    background-color: $wse-input-background;
    &--selected {
        border: 2px solid $wse-gen-marker;
        box-shadow: 0px 0px 5px 0px rgb(236, 140, 50);
    }
}



