.Donate {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .Donate__frame {
        width: 500px;
        max-width: 100%;
        max-height: 100%;
        
        padding: 20px;

        @media (min-width: 600px) {
            width: 600px;
            padding: 50px;
            border-radius: 20px;

            border: 1px solid #f7f7f7;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

        }
    }
}
