@import "styles/colors.scss";

.Resources {
  // &--Desktop {
  //     .Links {
  //         display: flex;
  //         justify-content: center;
  //         flex-wrap: wrap;
  //         gap: 16px;
  //     }

  //     .Links__group {
  //         max-width: 400px;
  //         height: max-content;
  //     }
  // }

  @media screen and (min-width: 500px) {
    .Links {
      margin: 0;
      padding: 0;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      display: grid;
      grid-template-columns: repeat(auto-fill, 400px);
      grid-auto-rows: 10px;
      justify-content: center;
      column-gap: 20px;

      max-width: 1300px;

    //   grid-template-columns: repeat(auto-fill, 100%);
    }
  }

  @media screen and (max-width: 499px) {
    .Links .Links__group {
      margin-bottom: 20px;
    }
  }

  .Links__link,
  .Links__link:hover {
    text-decoration: none;
  }

  $border: 2px;

  .Links__group {
    border-radius: 5px;
    background-color: #262626;
    border: $border solid #1e1e1e;
    margin-bottom: auto;

    > .Title {
      padding: 20px 20px 10px;
      margin: 0;
      height: 60px;
      color: #5d89d8;
    }

    > .Text {
      padding: 0 20px 20px;
      margin: 0;
      min-height: 75px;
    }
  }

  .Links__option {
    border: $border solid #1e1e1e;
    border-width: $border 0 0 0;
    cursor: pointer;
    color: white;
    height: 50px;
    padding-left: 20px;
    display: flex;
    align-items: center;

    font-family: Quicksand;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &--help {
      color: $wse-orange;
    }
  }
}
