@import "src/styles/colors.scss";

.Checkbox__wrapper {
  display: flex;
  margin: 0;
  padding-left: 15px;
  color: $wse-input-text-color;
}

.Checkbox--required .Checkbox__wrapper {
  margin: 5px;
  padding-right: 10px;
}

.Checkbox.Checkbox--required-open .Checkbox__wrapper {
  padding-top: 5px;
}

.Checkbox .Checkbox__checkbox {
  font-size: 24px;
  margin: 4px 0px;

  label {
    padding-left: 1.45em;
    line-height: 0.8;
  }
}

.Checkbox .Checkbox__label {
  font-size: 14px;
  margin: auto 0;
}

.Checkbox__required-value .FormCheckbox {
  margin-left: 10px;

  .FormCheckbox__label {
    font-style: italic;
  }
}

.Checkbox--required-open .Checkbox__wrapper {
  border-radius: 8px;
  background-color: $wse-checkbox-required-background;
}