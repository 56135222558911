// @import "src/styles/colors.scss";

// WSE object colors
$wse-text-color: #A8A8A8;
$wse-text-light-color: white;
$wse-bg: #303030;
$wse-input-border: #dddddd;
$wse-form-border: #d7d7d7;
$wse-input-background: $wse-bg;
$wse-input-text-color: rgb(207, 207, 207);
$wse-input-color: #868e96;
$wse-image-background: $wse-bg;
$wse-image-color: #bbbbbb;
$wse-button-group-border: #505050;
$wse-active-link: #e9c454;
$wse-title: #fff;
$wse-item-title: #7f7c7c;
$active-message-background: #f8f8f8;
$item-border: #ededed;
$wse-checkbox-required-background: #f6f6f6;

// WSE colors
$wse-white: #fff;
$wse-black: #000;
$wse-green: #025b6f;
$wse-light-green: #71ab5e;
$wse-grey: #9c9c9c;
$wse-light-grey: #f4f4f4;
$wse-dark-grey: #787878;
$wse-purple: #401b3e;
$wse-orange: #eb941c;
$wse-orange-light: rgb(255, 231, 187);
$wse-yellow: #e9c454;
$wse-red: #b80000;
$wse-bright-red: #ff0000;
$wse-light-red: #ff4242;
$wse-comparison-red: #d0021b;
$wse-comparison-green: #148814;
$wse-text-medium-grey: #a7a7a7;
$wse-text-light-grey: #d6d6d6;
$wse-valid-green: #099000;

$wse-topbar-background: #252525;
$wse-topbar-border: #eee;
$wse-topbar-selection-marker: orange;
$wse-navbar-selection-marker: orange;

$wse-gen-marker: orange;
$wse-gen-beta: orange;
$wse-gen-background: #3a3a3a;

