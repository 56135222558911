.Namespace.Inputs {
    margin: 0px;
}

.Gen__module--namespace {
    display: flex;
    flex-direction: column;
}

.Namespace {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.Namespace__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.NS {
    height: 30px;
    margin: 5px;
    display: flex;
    
    .text-input {
        width: 100%;
    }
}

.NS--namespace {
    width: 100%;
    margin-right: 20px;
}

.NS--prefix {
    margin-right: 10px;
    width: 100px;
    
}