
.Overview {
	width: 100%;
}

.OverviewTitle {

	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
	max-width: 1000px;

	.OverviewTitle__divider {
		height: 3px;
		width: 100%;
		margin: 0 20px;
		background-color: #3e3e3e;

	}

	> .Title {
		margin-bottom: 0;
		white-space: nowrap;
	}
}